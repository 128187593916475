import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { checkPermission } from "../helper/PermissionHelper";
import { menuOpenState } from "../reducers/commonSlice";
import { user } from "../reducers/userSlice";

// icon
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CategoryIcon from "@mui/icons-material/Category";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleIcon from "@mui/icons-material/People";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ViewListIcon from "@mui/icons-material/ViewList";
import SellIcon from "@mui/icons-material/Sell";
import DashboardIcon from "@mui/icons-material/Dashboard";

import {
  getCategoryPageName,
  getDashboardPageName,
  getInventoryPageName,
  getLandingPageName,
  getMemberPageName,
  getOrderPageName,
  getProductPageName,
  getSalesPageName,
  getSettingsPageName,
  getStorePageName,
  getSupplierPageName,
  getPromotionPageName,
} from "../helper/GlobalValueHelper";

export default function NestedList() {
  const { t } = useTranslation();
  const location = useLocation();
  const menuState = useSelector(menuOpenState);
  const currentUser = useSelector(user);

  // note: keep use <Translation> to avoid not rendering when language changed
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    setMenuList([
      {
        icon: <HomeIcon />,
        name: t("menu.home"),
        path: "/",
        havePermission: checkPermission(currentUser, getLandingPageName(), "view"),
      },
      {
        icon: <DashboardIcon />,
        name: t("menu.dashboard"),
        path: "/dashboard",
        havePermission: checkPermission(currentUser, getDashboardPageName(), "view"),
      },
      {
        icon: <PeopleIcon />,
        name: t("menu.members"),
        path: "/members",
        havePermission: checkPermission(currentUser, getMemberPageName(), "view"),
      },
      {
        icon: <PointOfSaleIcon />,
        name: t("menu.sales"),
        havePermission: checkPermission(currentUser, getSalesPageName(), "view"),
        sub: [
          {
            icon: <AccountTreeIcon />,
            name: t("menu.category"),
            path: "/category",
            havePermission: checkPermission(currentUser, getCategoryPageName(), "view"),
          },
          {
            icon: <InventoryIcon />,
            name: t("menu.inventory"),
            path: "/inventory",
            havePermission: checkPermission(currentUser, getInventoryPageName(), "view"),
          },
          {
            icon: <StoreIcon />,
            name: t("menu.stores"),
            path: "/stores",
            havePermission: checkPermission(currentUser, getStorePageName(), "view"),
          },
          {
            icon: <CategoryIcon />,
            name: t("menu.products"),
            path: "/products",
            havePermission: checkPermission(currentUser, getProductPageName(), "view"),
          },
          {
            icon: <SellIcon />,
            name: t("menu.promotions"),
            path: "/promotions",
            havePermission: checkPermission(currentUser, getPromotionPageName(), "view"),
          },
          {
            icon: <SupportAgentIcon />,
            name: t("menu.suppliers"),
            path: "/suppliers",
            havePermission: checkPermission(currentUser, getSupplierPageName(), "view"),
          },
          {
            icon: <ViewListIcon />,
            name: t("menu.orders"),
            path: "/orders",
            havePermission: checkPermission(currentUser, getOrderPageName(), "view"),
          },
        ],
      },
      {
        icon: <SettingsIcon />,
        name: t("menu.settings"),
        path: "/settings",
        havePermission: checkPermission(currentUser, getSettingsPageName(), "view"),
      },
    ]);
  }, [currentUser]);

  const handleClick = index => {
    menuList[index].open = !menuList[index].open;
    setMenuList([...menuList]);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          flex: 1,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <Divider></Divider>
          </ListSubheader>
        }
      >
        {menuList.map((list, index) =>
          list.havePermission ? (
            list.sub ? (
              <Box key={index}>
                <ListItemButton dense onClick={() => handleClick(index)}>
                  <ListItemIcon>{list.icon}</ListItemIcon>
                  <ListItemText primary={list.name} />
                  {list.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse in={list.open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {list.sub.map(
                      (sub, subIndex) =>
                        sub.havePermission && (
                          <ListItemButton
                            dense
                            key={`${subIndex}`}
                            component={Link}
                            sx={menuState ? { pl: 4 } : {}}
                            to={sub.path}
                          >
                            <ListItemIcon>{sub.icon}</ListItemIcon>
                            <ListItemText primary={sub.name} />
                          </ListItemButton>
                        ),
                    )}
                  </List>
                </Collapse>
              </Box>
            ) : (
              <ListItemButton
                dense
                key={index}
                component={Link}
                to={list.path}
                selected={location.pathname === list.path}
              >
                <ListItemIcon>{list.icon}</ListItemIcon>
                <ListItemText primary={list.name} />
              </ListItemButton>
            )
          ) : null,
        )}
      </List>
    </Box>
  );
}
