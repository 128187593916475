import axios from "../helper/AxiosHelper";

const productOptionValueList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/productOptionValue/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        name: data.name,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const productOptionValueFullList = async () => {
  const response = await axios.get(`/productOptionValue?fields=all`);
  const result = response.data.result.filter(data => data.name !== "");

  return result.map(data => {
    return {
      id: data.id,
      name: data.name,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      createdBy: data.created_by_info ? data.created_by_info.username : "",
      updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
    };
  });
};

const productOptionValueDetails = async id => {
  const response = await axios.get(`productOptionValue/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    addonPrice: result.addon_price,
    tagset: result.tagset_detail,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const createProductOptionValue = async input => {
  var data = {
    name: input.name,
    addon_price: input.addonPrice,
    tagset: input.tagset ? value.tagset.map(tag => tag.id) : [],
  };
  const response = await axios.post("/productOptionValue/create", data);

  return response.data.result.insert_id;
};

const updateProductOptionValue = async (id, input) => {
  var data = {
    name: input.name,
    addon_price: input.addonPrice,
    tagset: input.tagset.map(tag => tag.id),
  };
  const response = await axios.post(`/productOptionValue/update/${id}`, data);

  return response.data.success;
};

const deleteProductOptionValue = async id => {
  const response = await axios.post(`/productOptionValue/delete/${id}`);
  return response.data.success;
};

const productOptionList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/productOption/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        name: data.name,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const productOptionFullList = async () => {
  const response = await axios.get(`/productOption?fields=all`);
  const result = response.data.result;
  return result.map(data => {
    return {
      id: data.id,
      name: data.name,
      values: data.values.map(value => {
        return {
          id: value.id,
          name: value.name,
          addonPrice: value.addon_price,
          createdAt: value.created_at,
          updatedAt: value.updated_at,
          createdBy: value.created_by_info ? value.created_by_info.username : "",
          updatedBy: value.updated_by_info ? value.updated_by_info.username : "",
        };
      }),
    };
  });
};

const productOptionDetails = async id => {
  const response = await axios.get(`productOption/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    values: result.values,
    taskLocation: result.task_location,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const createProductOption = async input => {
  const data = {
    name: input.name,
    values: input.values.map(value => {
      return value.id;
    }),
  };
  const response = await axios.post("/productOption/create", data);

  return response.data.result.insert_id;
};

const updateProductOption = async (id, input) => {
  const data = {
    name: input.name,
    values: input.values.map(value => {
      return value.id;
    }),
    task_location: input.taskLocation,
  };
  const response = await axios.post(`/productOption/update/${id}`, data);

  return response.data.success;
};

const deleteProductOption = async id => {
  const response = await axios.post(`/productOption/delete/${id}`);
  return response.data.success;
};

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC",
  };

  const response = await axios.get(`/product/query?query=${JSON.stringify(query)}&fields=all`);

  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        title: data.title,
        type: data.type,
        isAvailable: data.is_available,
        description: data.description,
        categories: data.categories,
        supplier: data.supplier,
        options: data.options,
        images: data.images,
        variants: data.variants.map(variant => {
          const optionMapping = variant.option_values;
          let optionValues = [];
          for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
            optionValues.push(attrValue.id);
          }
          return {
            id: variant.id,
            productId: variant.product_id,
            sku: variant.sku,
            stock: variant.stock,
            price: variant.price,
            barcode: variant.barcode,
            optionValues: optionValues,
            optionMapping: variant.option_values,
          };
        }),
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const fullList = async query => {
  const response = await axios.get(`/product?query=${JSON.stringify(query)}fields=all`);

  const result = response.data.result;
  return result.map(data => {
    return {
      id: data.id,
      title: data.title,
      type: data.type,
      description: data.description,
      categories: data.categories,
      supplier: data.supplier,
      options: data.options,
      variants: data.variants.map(variant => {
        const optionMapping = variant.option_values;
        let optionValues = [];
        for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
          if (attrValue && attrValue.id) {
            optionValues.push(attrValue.id);
          }
        }
        return {
          id: variant.id,
          productId: variant.product_id,
          sku: variant.sku,
          cost: variant.cost,
          price: variant.price,
          barcode: variant.barcode,
          optionValues: optionValues,
          optionMapping: variant.option_values,
        };
      }),
      images: data.images,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      createdBy: data.created_by_info ? data.created_by_info.username : "",
      updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
    };
  });
};

const queryByStore = async (storeId, page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC",
  };
  const response = await axios.get(
    `/product/queryStore?query=${JSON.stringify(query)}&fields=all&store=${storeId}`,
  );

  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        title: data.title,
        type: data.type,
        description: data.description,
        ingredients: data.ingredients,
        attribute: data.attribute,
        categories: data.categories,
        options: data.options,
        images: data.images,
        isSimple: data.is_simple,
        variants: data.variants.map(variant => {
          const optionMapping = variant.option_values;
          let optionValues = [];
          for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
            if (attrValue && attrValue.id) {
              optionValues.push(attrValue.id);
            }
          }
          return {
            id: variant.id,
            productId: variant.product_id,
            sku: variant.sku,
            cost: variant.cost,
            price: variant.price,
            barcode: variant.barcode,
            optionValues: optionValues,
            optionMapping: variant.option_values,
            isAvailable: variant.is_available,
          };
        }),
        images: data.images,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`product/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    type: result.type,
    isAvailable: result.is_available,
    title: result.title,
    description: result.description,
    categories: result.categories,
    inventories: result.inventories,
    supplier: result.supplier,
    options: result.options,
    variants:  result.variants
      ? result.variants.map(variant => {
      const optionMapping = variant.option_values;
      let optionValues = [];
      for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
        if (attrValue && attrValue.id) {
          optionValues.push(attrValue.id);
        }
      }
      return {
        id: variant.id,
        productId: variant.product_id,
        sku: variant.sku,
        cost: variant.cost,
        price: variant.price,
        barcode: variant.barcode,
        optionValues: optionValues,
        optionMapping: variant.option_values,
        isAvailable: variant.is_available,
        imageIndex: variant.image_index,
      };
    }) : [],
    services: result.services
      ? result.services.map(service => {
          return {
            id: service.id,
            variantId: service.variant_id,
            quota: service.quota,
            expireType: service.expire_type,
            expireDate: service.expire_date,
            expirePeriod: service.expire_period,
          };
        })
      : [],
    images: result.images,
    isSimple: result.is_simple,
    trackInventory: result.track_inventory,
    printKitchenReceipt: result.print_kitchen_receipt,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const createProduct = async input => {
  const formData = new FormData();
  const data = {
    ...input,
    is_simple: input.isSimple,
    track_inventory: input.trackInventory,
    print_kitchen_receipt: input.printKitchenReceipt,
    categories: input.categories.map(category => category.id),
    inventories: input.inventories.map(inventory => inventory.id),
    supplier: input.supplier ? input.supplier.id : null,
    description: input.description,
    variants: input.variants.map(variant => {
      let addonPrice = 0;
      if (variant.optionMapping) {
        for (const [key, value] of Object.entries(variant.optionMapping)) {
          addonPrice += value.addonPrice || 0;
        }
      }
      return {
        temp_id: variant.tempId,
        sku: variant.sku,
        barcode: variant.barcode,
        cost: parseFloat(variant.cost),
        price: parseFloat(variant.price) + addonPrice,
        option_values: variant.optionValues,
        is_available: variant.isAvailable,
        image_index: variant.imageIndex,
      };
    }),
    services: input.services.map(service => {
      return {
        id: service.id,
        temp_id: service.tempId,
        quota: service.quota,
        description: service.description,
        expire_type: service.expireType,
        expire_period: service.expirePeriod,
        expire_date: service.expireDate,
        remark: service.remark,
      };
    }),
    images: input.images || [],
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post("/product/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const updateProduct = async input => {
  const formData = new FormData();

  const data = {
    ...input,
    is_simple: input.isSimple,
    is_available: input.isAvailable,
    track_inventory: input.trackInventory,
    print_kitchen_receipt: input.printKitchenReceipt,
    categories: input.categories.map(category => category.id),
    inventories: input.inventories.map(inv => inv.id),
    supplier: input.supplier ? input.supplier.id : null,
    description: input.description,
    variants: input.variants.map(variant => {
      let addonPrice = 0;
      for (const [key, value] of Object.entries(variant.optionMapping)) {
        addonPrice += value.addonPrice || 0;
      }

      return {
        temp_id: variant.tempId,
        id: variant.id ? variant.id : undefined,
        sku: variant.sku,
        barcode: variant.barcode,
        cost: parseFloat(variant.cost),
        price: parseFloat(variant.price) + addonPrice,
        option_values: variant.optionValues,
        is_available: variant.isAvailable,
        image_index: variant.imageIndex,
      };
    }),
    services: input.services.map(service => {
      return {
        id: service.id,
        temp_id: service.tempId,
        quota: service.quota,
        description: service.description,
        expire_type: service.expireType,
        expire_period: service.expirePeriod,
        expire_date: service.expireDate,
        remark: service.remark,
      };
    }),
    images: input.images || [],
    isAvailable: undefined,
    isSimple: undefined,
    trackInventory: undefined,
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`/product/update/${data.id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`product/delete/${id}`);
  return response.data.success;
};

export const ProductApi = {
  productOptionValueList,
  productOptionValueFullList,
  productOptionValueDetails,
  createProductOptionValue,
  updateProductOptionValue,
  deleteProductOptionValue,
  productOptionList,
  productOptionFullList,
  productOptionDetails,
  createProductOption,
  updateProductOption,
  deleteProductOption,
  list,
  fullList,
  queryByStore,
  details,
  createProduct,
  updateProduct,
  deleteData,
};
