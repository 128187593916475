import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getWellcomePageName, getDashboardPageName } from "../helper/GlobalValueHelper";
import Wellcome from "../views/Wellcome";
import Dashboard from "../views/Dashboard";

const HomeRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/",
      element: (
        <AuthProvider page={getWellcomePageName()} action={"view"}>
          <Wellcome />
        </AuthProvider>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AuthProvider page={getDashboardPageName()} action={"view"}>
          <Dashboard />
        </AuthProvider>
      ),
    },
  ],
};

export default HomeRoute;
