import * as React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardHeader,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next"; // Functional Components
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import palette from "../../styles/theme/palette";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export default function OrderSummaryChart({ data }) {
  const { t } = useTranslation();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `${data.dateRange.map((item) => item).join(" ~ ")}`,
      },
    },
  };

  const labels = data.result.map((item) => item.date);

  const value = {
    labels,
    datasets: [
      {
        label: `${t("dashboard.order_total")}`,
        data: data.result.map((item) => item.value),
        borderColor: palette.primary.main,
        backgroundColor: palette.primary.dark,
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  return (
    <Card variant="outlined">
      {/* <CardHeader title={t(`dashboard.${data.rangeCode}`)} /> */}
      {/* <Divider /> */}

      <Box sx={{ p: 2 }}>
        <Bar style={{ width: "100%" }} options={options} data={value} />
      </Box>
    </Card>
  );
}
